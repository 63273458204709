import { Component, Field, HasMany, HasOne, StaticRender } from '@contember/interface'
import { InvitationForm } from '~/app/components/forms/invitation-form'
import { QuestionAnswerForm } from '~/app/components/forms/question-answer-form'
import { InvitationPreview } from '~/app/components/previews/invitation-preview'
import { CheckboxField, FormLayout, SelectField, TextareaField } from '~/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '~/lib/repeater'


export const ResponseForm = Component(() => <FormLayout>
	<SelectField field="invitation" label="Invitation" createNewForm={<InvitationForm over="invitation" />}>
		<InvitationPreview />
		<StaticRender>
			<Field field="rejectReason" />
			<HasMany field="invitees">
				<Field field="name" />
				<Field field="willAttend" />
			</HasMany>
		</StaticRender>
	</SelectField>
	<HasMany field="invitation.invitees">
		<CheckboxField field="willAttend" label={<Field field="name" />} />
	</HasMany>
	<TextareaField field="invitation.rejectReason" label="Reject reason" />
</FormLayout>)
