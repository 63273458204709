import { useEffect } from 'react'
import { Component, useField } from '@contember/interface'
import { usePersistWithFeedback } from '~/lib/binding'
import { CheckboxFieldProps, CheckboxField } from '~/lib/form'

export const PersistCheckbox = Component((props: CheckboxFieldProps) => {
	const field = useField(props.field)
	const persist = usePersistWithFeedback()
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (field.value !== field.valueOnServer) {
			persist()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.value, field.valueOnServer])
	return (<CheckboxField {...props} />)
}, (props: CheckboxFieldProps) => (
	<CheckboxField {...props} />
), 'PersistCheckbox')
