import { Component } from '@contember/interface'
import { Menu, MenuItem } from '~/lib/ui/menu'
import { ActivityIcon, FileTextIcon, MessageSquareIcon, UsersIcon } from 'lucide-react'

export const Navigation = Component(() => <Menu>
	<MenuItem label="Invitations" icon={<ActivityIcon />} to="invitationList" />
	<MenuItem label="Rsvp forms" icon={<FileTextIcon />} to="rsvpFormList" />
	<MenuItem label="Guests" icon={<UsersIcon />} to="guestList" />
	<MenuItem label="Responses" icon={<MessageSquareIcon />} to="responseList" />
</Menu>)
