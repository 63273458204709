import { Component, useEntity } from '@contember/interface'
import { useState } from 'react'
import { ClipboardCheckIcon, ClipboardCopyIcon } from 'lucide-react'
import { Button } from '~/lib/ui/button'

type CopyToClipboardButtonProps = {
	title?: string
	text: string
}

const CopyToClipboardButton = Component<CopyToClipboardButtonProps>(
	({ text, title }) => {
		const entity = useEntity()
		const [copied, setCopied] = useState(false)

		if (!entity.existsOnServer) {
			return null
		}

		return (
			<Button
				variant="outline"
				size="icon"
				title={title}
				onClick={() => {
					navigator.clipboard.writeText(text.replace('UUID', (entity.idOnServer ?? '').toString()))
					setCopied(true)
				}}
			>
				{copied ? <ClipboardCheckIcon /> : <ClipboardCopyIcon />}
			</Button >
		)
	},
	() => null,
	'CopyToClipboardButton',
)

export default CopyToClipboardButton
