import { Component } from '@contember/interface'
import { InvitationForm } from '~/app/components/forms/invitation-form'
import { InvitationPreview } from '~/app/components/previews/invitation-preview'
import { CheckboxField, FormLayout, InputField, SelectField } from '~/lib/form'

export interface InviteeFormProps { over?: 'invitees' | (string & {}) }

export const InviteeForm = Component(({ over } : InviteeFormProps) => <FormLayout>
	<InputField field="name" label="Name" required />
	{over !== 'invitees' && <SelectField field="invitation" label="Invitation" createNewForm={<InvitationForm over="invitation" />}>
	<InvitationPreview />
</SelectField>
}
	<CheckboxField field="willAttend" label="Will attend" />
</FormLayout>)
