import { Component } from '@contember/interface'
import { InviteeForm } from '~/app/components/forms/invitee-form'
import { RsvpFormForm } from '~/app/components/forms/rsvp-form-form'
import { RsvpFormPreview } from '~/app/components/previews/rsvp-form-preview'
import { FormLayout, InputField, SelectField } from '~/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '~/lib/repeater'
import { RichText } from '../rich-text'

export interface InvitationFormProps { over?: 'invitation' | (string & {}) }

export const InvitationForm = Component(({ over }: InvitationFormProps) => <FormLayout>
	<InputField field="name" label="Name" />
	<RichText field="introText" label="Intro text" />
	<DefaultRepeater field="invitees" title="Invitees" sortableBy="displayOrder">
		<RepeaterItemActions>
			<RepeaterRemoveItemButton />
		</RepeaterItemActions>
		<InviteeForm over="invitees" />
	</DefaultRepeater>
	<SelectField field="rsvpForm" label="Rsvp form" createNewForm={<RsvpFormForm />}>
		<RsvpFormPreview />
	</SelectField>
</FormLayout>)
