import { Component } from '@contember/interface'
import { FormLayout, InputField, SelectField } from '~/lib/form'
import { QuestionPreview } from '../previews/question-preview'

export const OptionForm = Component<{ enableLeadsTo?: boolean }>(({ enableLeadsTo }, env) => <FormLayout>
	<InputField field="value" label="Value" required />
	{enableLeadsTo && <SelectField
		field="leadsTo"
		label="Leads to"
		options={{
			entityName: 'Question',
			filter: { rsvpForm: { id: { eq: env.hasParameter('id') ? env.getParameter('id') : '00000000-0000-0000-0000-000000000000' } } }
		}}
	>
		<QuestionPreview />
	</SelectField>}
</FormLayout>)
