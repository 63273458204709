import { Component } from '@contember/interface'
import { QuestionForm } from '~/app/components/forms/question-form'
import { FormLayout, InputField, MultiSelectField } from '~/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '~/lib/repeater'
import { InvitationPreview } from '../previews/invitation-preview'


export const RsvpFormForm = Component(() => <FormLayout>
	<InputField field="name" label="Name" required />
	<MultiSelectField field="invitations" label="Invitations">
		<InvitationPreview />
	</MultiSelectField>
	<DefaultRepeater field="questions" title="Questions" sortableBy="displayOrder">
		<RepeaterItemActions>
			<RepeaterRemoveItemButton />
		</RepeaterItemActions>
		<QuestionForm />
	</DefaultRepeater>
</FormLayout>)
