import { Component, Field, HasMany, HasOne } from '@contember/interface'
import { InvitationPreview } from '~/app/components/previews/invitation-preview'
import { QuestionAnswerPreview } from '~/app/components/previews/question-answer-preview'
import { Table, TableBody, TableCell, TableRow, TableWrapper } from '~/lib/ui/table'
import { nl2brJsx } from '../nl2br'

export const ResponseDetail = Component(() => <TableWrapper className="bg-gray-50/50 max-w-lg border rounded-md">
	<Table>
		<TableBody>
			<TableRow>
				<TableCell>
					Invitation
				</TableCell>
				<TableCell className="font-semibold">
					<HasOne field="invitation">
						<InvitationPreview />
					</HasOne>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					Invitee
				</TableCell>
				<TableCell className="font-semibold">
					<HasMany field="invitation.invitees">
						<div>
							<Field field="name" /> - <Field<boolean> field="willAttend" format={value => value === null ? 'N/A' : value ? 'Yes' : 'No'} />
						</div>
					</HasMany>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					Contact e-mail
				</TableCell>
				<TableCell className="font-semibold">
					<Field<string> field="contactEmail" />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					Answers
				</TableCell>
				<TableCell>
					<HasMany field="answers">
						<QuestionAnswerPreview />
						<br />
					</HasMany>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					Reject reason
				</TableCell>
				<TableCell className="font-semibold">
					<Field<string> field="invitation.rejectReason" format={value => nl2brJsx(value ?? '')} />
				</TableCell>
			</TableRow>
		</TableBody>
	</Table>
</TableWrapper>)
