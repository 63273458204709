import { Component, If } from '@contember/interface'
import { OptionForm } from '~/app/components/forms/option-form'
import { FormLayout, InputField, RadioEnumField } from '~/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '~/lib/repeater'
import { questionTypeOptions } from '../labels'

export const QuestionForm = Component(() => <FormLayout>
	<InputField field="questionText" label="Question text" required />

	<RadioEnumField
		field="questionType"
		label="Question type"
		options={questionTypeOptions}
		defaultValue="text"
	/>
	<If condition="[questionType='checkbox']">
		<DefaultRepeater field="options" title="Options" sortableBy="displayOrder">
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
			<OptionForm />
		</DefaultRepeater>
	</If>
	<If condition="[questionType = 'radio']">
		<DefaultRepeater field="options" title="Options" sortableBy="displayOrder">
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
			<OptionForm enableLeadsTo />
		</DefaultRepeater>
	</If>
</FormLayout>)
