export const Logo = () => {
	return (
		<svg className={'w-full'} width="456" height="46" viewBox="0 0 456 66" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path d="M59.829 36.9343L54.9863 32.9984L48.7821 38.106C44.0053 42.0419 39.7007 46.5693 35.9672 51.5745L32.3105 56.4887L28.5111 51.4721C24.4152 46.0574 19.7043 41.1774 14.4773 36.9343L9.6347 32.9984L12.5117 30.6665C19.0454 25.3655 24.9313 19.2683 30.0484 12.4999L32.2995 9.51957L34.0455 11.8174C39.5031 19.0294 45.7842 25.5362 52.7572 31.2011L54.9754 33.0098L57.8524 30.6779C59.6752 29.199 61.4542 27.6406 63.1782 26.0367C60.0925 11.2941 47.4533 0.259979 32.3105 0.259979C14.8617 0.259979 0.130859 14.9229 0.130859 32.9984C0.130859 51.074 14.8617 65.7369 32.3105 65.7369C47.4973 65.7369 60.1804 54.6345 63.2111 39.8464C62.102 38.8454 60.982 37.8671 59.818 36.9343H59.829Z"/>
			<path d="M90.9851 32.8245C90.9851 18.25 102.505 7.2533 118.071 7.2533C122.75 7.2533 128.123 8.79294 132.311 11.5983V22.458C128.543 18.7431 123.373 16.6446 118.421 16.6446C108.298 16.6446 101.457 23.51 101.457 32.8245C101.457 42.1391 108.298 48.8675 118.421 48.8675C123.378 48.8675 128.543 46.7635 132.311 43.0541V53.9138C128.123 56.7137 122.745 58.2588 118.071 58.2588C102.505 58.2533 90.9851 47.3224 90.9851 32.8245Z"/>
			<path d="M136.564 39.6187C136.564 28.9727 144.939 20.9128 155.831 20.9128C166.724 20.9128 175.099 28.9672 175.099 39.6187C175.099 50.2702 166.653 58.2533 155.831 58.2533C145.01 58.2533 136.564 50.1277 136.564 39.6187ZM155.831 49.3552C161.138 49.3552 165.184 45.2239 165.184 39.6187C165.184 34.0135 161.133 29.811 155.831 29.811C150.53 29.811 146.479 34.0135 146.479 39.6187C146.479 45.2239 150.53 49.3552 155.831 49.3552Z"/>
			<path d="M215.234 37.5859V57.4095H205.461V37.9366C205.461 33.0327 202.529 29.811 198.063 29.811C193.597 29.811 190.665 33.0327 190.665 37.9366V57.4095H180.892V37.5859C180.892 27.4988 187.662 20.9128 198.063 20.9128C208.464 20.9128 215.234 27.4988 215.234 37.5859Z"/>
			<path d="M234.71 12.1572V21.7567H246.437V29.8822H234.71V42.561C234.71 47.6073 237.57 49.7058 240.644 49.7058C243.156 49.7058 244.903 49.0045 246.437 48.1662V56.7137C243.926 57.7657 241.201 58.2533 238.897 58.2533C230.937 58.2533 224.865 53.4207 224.865 43.1199V29.8822H219.144V21.7567H224.865V12.1572H234.71Z"/>
			<path d="M249.44 39.6186C249.44 29.1808 256.702 20.984 267.381 20.984C278.759 20.984 284.973 30.1616 283.367 41.6514H258.099C259.077 46.8347 263.335 50.2701 270.035 50.2701C274.501 50.2701 278.83 48.5168 282.183 45.6457V53.9795C279.322 56.4999 274.572 58.2532 268.364 58.2532C257.057 58.2532 249.446 50.7578 249.446 39.6186H249.44ZM274.009 35.1367C273.731 31.2136 270.728 28.3425 266.611 28.3425C262.495 28.3425 259.492 30.6547 258.372 35.1367H274.009Z"/>
			<path d="M299.976 38.1448V57.4095H290.203V21.7566H299.976V27.2194C302.002 23.1538 306.119 20.9128 309.749 20.9128C314.843 20.9128 318.403 23.0114 320.358 27.0057C323.148 23.0114 326.991 20.9128 331.943 20.9128C339.833 20.9128 344.507 26.518 344.507 36.6764V57.4095H334.733V37.5147C334.733 32.7533 332.429 29.811 328.799 29.811C324.818 29.811 322.236 33.0327 322.236 38.1448V57.4095H312.463V37.5147C312.463 32.7533 310.159 29.811 306.599 29.811C302.548 29.811 299.965 33.0327 299.965 38.1448H299.976Z"/>
			<path d="M362.311 52.018V57.4095H352.538V8.09162H362.311V27.1481C364.473 23.1538 368.524 20.9128 373.689 20.9128C383.32 20.9128 390.931 28.8302 390.931 39.6187C390.931 50.4072 383.32 58.2533 373.689 58.2533C368.524 58.2533 364.473 56.0123 362.311 52.018ZM371.664 49.3552C376.971 49.3552 381.016 45.2239 381.016 39.6187C381.016 34.0135 376.965 29.811 371.664 29.811C366.362 29.811 362.311 34.0135 362.311 39.6187C362.311 45.2239 366.362 49.3552 371.664 49.3552Z"/>
			<path d="M395.256 39.6186C395.256 29.1808 402.517 20.984 413.197 20.984C424.575 20.984 430.788 30.1616 429.183 41.6514H403.915C404.892 46.8347 409.151 50.2701 415.85 50.2701C420.316 50.2701 424.646 48.5168 427.998 45.6457V53.9795C425.137 56.4999 420.387 58.2532 414.179 58.2532C402.872 58.2532 395.261 50.7578 395.261 39.6186H395.256ZM419.825 35.1367C419.546 31.2136 416.543 28.3425 412.427 28.3425C408.31 28.3425 405.307 30.6547 404.188 35.1367H419.825Z"/>
			<path d="M451.655 30.0904C447.539 30.0904 445.306 33.3834 445.306 39.1256V57.4095H435.533V37.5147C435.533 27.3563 439.791 20.9128 449.843 20.9128C451.306 20.9128 454.658 21.2635 455.985 21.7512V31.0657C455.286 30.5781 452.633 30.085 451.655 30.085V30.0904Z"/>
		</svg>
	)
}
