import { Field, Link } from '@contember/interface'
import { Binding } from '~/lib/binding'
import { BackButton } from '~/lib/buttons'
import { DataGrid, DataGridBooleanColumn, DataGridColumn, DataGridHasOneColumn, DataGridLoader, DataGridPagination, DataGridQueryFilter, DataGridTable, DataGridTextColumn, DataGridToolbar } from '~/lib/datagrid'
import { Slots } from '~/lib/layout'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>
						Guest list
					</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<DataGrid entities="Invitee">
						<DataGridToolbar>
							<DataGridQueryFilter />
						</DataGridToolbar>
						<DataGridLoader>
							<DataGridTable>
								<DataGridColumn>
									<div className="flex gap-4">
										<Link to="invitationEdit(id: $entity.invitation.id)">
											<a>
												Edit
											</a>
										</Link>
									</div>
								</DataGridColumn>
								<DataGridTextColumn field="name" header="Name" />
								<DataGridBooleanColumn field="willAttend" header="Will attend" />
								<DataGridHasOneColumn field="invitation" header="Invitaiton">
									<Field field="name" />
								</DataGridHasOneColumn>
							</DataGridTable>
						</DataGridLoader>
						<DataGridPagination />
					</DataGrid>
				</div>
			</Binding>
		</>
	)
}
