import { Fragment } from 'react'

export const nl2brJsx = (text?: string) => {
	const lines = (text ?? '').split('\n')
	if (lines.length <= 1) {
		return text
	}
	return lines.map((line, i) => (
		<Fragment key={i}>
			{!!i && <br />}
			{line}
		</Fragment>
	))
}
