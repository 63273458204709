import { Component, Field, HasMany, If } from '@contember/interface'
import { nl2brJsx } from '../nl2br'

export const QuestionAnswerPreview = Component(() => <div>
	<Field field="question.questionText" />
	<br />
	<If condition="[answerText!=null]">
		<strong><Field<string> field="answerText" format={value => nl2brJsx(value ?? '')} /></strong>
	</If>
	<HasMany field="selectedOptions">
		<div>
			<strong><Field field="value" /></strong>
		</div>
	</HasMany>
</div>)
