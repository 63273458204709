import { useEffect } from 'react'
import { useProjectUserRoles, useRedirect } from '@contember/interface'

export default () => {
	const redirect = useRedirect()

	useEffect(() => {
		redirect('invitationList')
	}, [redirect])

	return null
}
